import { useMemo } from '../../_snowpack/pkg/react.js';
import { useQuery } from '../../_snowpack/pkg/@apollo/react-hooks.js';
import { GET_STOCK_LOCATIONS } from '../queries/index.js';
import useTenant from './useTenant.js';

function useStockLocations() {
  const {
    tenant: {
      id: tenantId
    }
  } = useTenant();
  const {
    error,
    data
  } = useQuery(GET_STOCK_LOCATIONS, {
    variables: {
      tenantId
    }
  });

  if (error) {
    console.error(`Could not retrieve tenant stock locations`);
  }

  const stockLocations = useMemo(() => {
    var _data$stockLocation$g;

    return ((_data$stockLocation$g = data === null || data === void 0 ? void 0 : data.stockLocation.getMany) !== null && _data$stockLocation$g !== void 0 ? _data$stockLocation$g : []).reduce((acc, val) => {
      val.identifier === 'default' ? acc.unshift(val) : acc.push(val);
      return acc;
    }, []);
  }, [data === null || data === void 0 ? void 0 : data.stockLocation.getMany]);
  return stockLocations;
}

export default useStockLocations;