import { useReducer, useCallback } from '../../_snowpack/pkg/react.js';
var ActionType;

(function (ActionType) {
  ActionType["Undo"] = "UNDO";
  ActionType["Redo"] = "REDO";
  ActionType["Set"] = "SET";
  ActionType["Reset"] = "RESET";
})(ActionType || (ActionType = {}));

const initialState = {
  past: [],
  present: null,
  future: []
};

const reducer = (state, action) => {
  const {
    past,
    present,
    future
  } = state;

  switch (action.type) {
    case ActionType.Undo:
      {
        if (past.length === 0) {
          return state;
        }

        const previous = past[past.length - 1];
        const newPast = past.slice(0, past.length - 1);
        return {
          past: newPast,
          present: previous,
          future: [present, ...future]
        };
      }

    case ActionType.Redo:
      {
        if (future.length === 0) {
          return state;
        }

        const next = future[0];
        const newFuture = future.slice(1);
        return {
          past: [...past, present],
          present: next,
          future: newFuture
        };
      }

    case ActionType.Set:
      {
        const {
          newPresent
        } = action;

        if (newPresent === present) {
          return state;
        }

        return {
          past: [...past, present],
          present: newPresent,
          future: []
        };
      }

    case ActionType.Reset:
      {
        const {
          newPresent
        } = action;
        return {
          past: [],
          present: newPresent,
          future: []
        };
      }
  }
};

const useUndoRedo = initialPresent => {
  const [state, dispatch] = useReducer(reducer, { ...initialState,
    present: initialPresent
  });
  const canUndo = state.past.length !== 0;
  const canRedo = state.future.length !== 0;
  const undo = useCallback(() => {
    if (canUndo) {
      dispatch({
        type: ActionType.Undo
      });
    }
  }, [canUndo]);
  const redo = useCallback(() => {
    if (canRedo) {
      dispatch({
        type: ActionType.Redo
      });
    }
  }, [canRedo]);
  const set = useCallback(newPresent => dispatch({
    type: ActionType.Set,
    newPresent
  }), []);
  const reset = useCallback(newPresent => dispatch({
    type: ActionType.Reset,
    newPresent
  }), []);
  return [state, {
    set,
    reset,
    undo,
    redo,
    canUndo,
    canRedo
  }];
};

export default useUndoRedo;