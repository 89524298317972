import styled from '../../../../_snowpack/pkg/styled-components.js'; // @ts-expect-error

import is from '../../../../_snowpack/pkg/styled-is.js';
import LoadingSpinner from '../../loading-spinner.js'; // @ts-expect-error

import { colors, responsive, speed, sizes } from '../../../css/index.js';

function ensurePx(width) {
  if (typeof width === 'number') {
    return `${width}px`;
  }

  if (typeof width === 'string' && !width.match(/px/)) {
    return `${width}px`;
  }

  return width;
}

export const LayoutSC = styled.div.withConfig({
  displayName: "styles__LayoutSC",
  componentId: "dtxd88-0"
})(["display:flex;min-width:0;background:", ";height:100vh;"], colors.frost);
export const AsideLoadingSpinner = styled(LoadingSpinner).withConfig({
  displayName: "styles__AsideLoadingSpinner",
  componentId: "dtxd88-1"
})(["margin-right:15px;"]);
export const AsideContent = styled.div.withConfig({
  displayName: "styles__AsideContent",
  componentId: "dtxd88-2"
})(["flex:1 1 auto;overflow:", ";-webkit-overflow-scrolling:touch;scroll-behavior:smooth;display:flex;flex-direction:column;transition:transform ", "ms;", ";"], p => p.hideOverflow ? 'hidden' : 'auto', speed.medium, is('hidden')`
    transform: translateX(-100vw);
    width: 1px;
  `);
export const AsideLoading = styled.div.withConfig({
  displayName: "styles__AsideLoading",
  componentId: "dtxd88-3"
})(["margin:0 47px;font-size:0.7rem;display:flex;flex:1 1 auto;align-items:center;> svg{margin-right:", ";}", ";"], sizes.tiny, is('hidden')`
    display: none;
  `);
export const AsideSC = styled.aside.withConfig({
  displayName: "styles__AsideSC",
  componentId: "dtxd88-4"
})(["flex:0 0 calc(", " - 2px);padding:0 ", " 0;display:flex;flex-direction:column;transition:all ", "ms,padding ", "ms;border-right:2px dashed rgba(0,0,0,0.1);border-right:1px solid #dfdfdf;position:relative;", ";", ";"], p => ensurePx(p.width), sizes.small, speed.medium, speed.medium, is('noPadding')`
    padding: 6px 0 0 0;
  `, is('collapsed')`
    flex: 0 0 ${p => ensurePx(p.width)};
    padding: 0;
    margin-left:calc(-${p => ensurePx(p.width)} - 2px);
  `);
export const ContentSC = styled.div.withConfig({
  displayName: "styles__ContentSC",
  componentId: "dtxd88-5"
})(["display:flex;flex-direction:column;flex:1 1 100%;height:100vh;margin-right:0;padding-left:0;position:relative;z-index:1;overflow-y:auto;scroll-behavior:smooth;-webkit-overflow-scrolling:touch;", " ", " *{box-sizing:border-box;}box-shadow:", ";transition:", ";", "{min-width:100%;}", ""], is('$spaceAtTop')`
    padding-top: 41px
  `, is('center')`
    display: flex;
    align-items: center;
    justify-content: center;
  `, p => p.raised && '0 19px 38px rgba(0,0,0,0.2)', p => p.raised && '0.3s', responsive.xs, is('$disabled')`
    opacity: 0.15;
    pointer-events: none;
  `);
export const CollapseButton = styled.button.withConfig({
  displayName: "styles__CollapseButton",
  componentId: "dtxd88-6"
})(["display:block;position:absolute;bottom:45px;width:40px;height:40px;border-radius:50%;left:300px;cursor:pointer;color:", ";opacity:1;transform:translateX(-50%);transition:all 150ms;background:", ";border:1px solid #dfdfdf;z-index:99;svg{width:15px;height:15px;position:absolute;left:50%;top:50%;transform:translate(-60%,-50%) scale(-1,-1);transition:all 0.2s ease-in-out;", ";path{fill:rgba(0,0,0,0.15);}}&:hover{text-decoration:underline;border:2px solid rgba(0,0,0,0.4);}", ";", "{bottom:135px;}"], colors.frostbite, colors.frost, is('collapsed')`
      transform: translate(-40%, -50%) scale(1, 1);
    `, is('collapsed')`
    transform: translateX(-275px);
    border: 1px solid #dfdfdf;
    svg {
      path {
        fill: ${colors.frostbite};
      }
    }
  `, responsive.xs);