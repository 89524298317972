// @ts-expect-error
import Add from './add.js';
import Arrow from './arrow.js'; // @ts-expect-error

import ArrowLeft from './arrow-left.js'; // @ts-expect-error

import ArrowRight from './arrow-right.js'; // @ts-expect-error

import BackArrow from './backArrow.js'; // @ts-expect-error

import BackArrow1 from './backArrow1.js'; // @ts-expect-error

import Bandwidth from './bandwidth.js'; // @ts-expect-error

import BillingAddress from './billing-address.js'; // @ts-expect-error

import Calendar from './calendar.js'; // @ts-expect-error

import CalendarColor from './calendar-color.js'; // @ts-expect-error

import CalendarOutlined from './calendar-outlined.js';
import Cancel from './cancel.js'; // @ts-expect-error

import Catalogue from './catalogue.js';
import Check from './check.js';
import Chevron from './chevron.js'; // @ts-expect-error

import Chunk from './chunk.js'; // @ts-expect-error

import Chunks from './chunks.js';
import ChunkSlice from './chunk-slice.js'; // @ts-expect-error

import Clock from './clock.js'; // @ts-expect-error

import Cloud from './cloud.js'; // @ts-expect-error

import CloudWithCross from './cloud-with-cross.js'; // @ts-expect-error

import CloudWithKey from './cloud-with-key.js'; // @ts-expect-error

import CloudWithKeyHole from './cloud-with-key-hole.js'; // @ts-expect-error

import Code from './code.js'; // @ts-expect-error

import Cog from './cog.js';
import Copy from './copy.js'; // @ts-expect-error

import Create from './create.js'; // @ts-expect-error

import CurrencyBill from './currency-bill.js'; // @ts-expect-error

import Customers from './customers.js'; // @ts-expect-error

import Dashboard from './dashboard.js'; // @ts-expect-error

import DeadTeddy from './dead-teddy.js'; // @ts-expect-error

import Delete from './delete.js'; // @ts-expect-error

import Document from './document.js'; // @ts-expect-error

import Dots from './dots.js'; // @ts-expect-error

import DragCircles from './drag-circles.js'; // @ts-expect-error

import Duplicate from './duplicate.js'; // @ts-expect-error

import Edit from './edit.js'; // @ts-expect-error

import Email from './email.js'; // @ts-expect-error

import Folder from './folder.js'; // @ts-expect-error

import FormatBold from './format_bold.js'; // @ts-expect-error

import FormatBullet from './format_list_bulleted.js'; // @ts-expect-error

import FormatCode from './code.js'; // @ts-expect-error

import FormatItalic from './format_italic.js'; // @ts-expect-error

import FormatLink from './link.js'; // @ts-expect-error

import FormatList from './format_list_numbered.js'; // @ts-expect-error

import FormatQuote from './format_quote.js'; // @ts-expect-error

import FormatUnderline from './format_underline.js'; // @ts-expect-error

import Fulfilment from './fulfilment.js'; // @ts-expect-error

import FulfilmentOrder from './fulfilment-order.js'; // @ts-expect-error

import FulfilmentOrderOpen from './fulfilment-order-open.js'; // @ts-expect-error

import FulfilmentOrderWhite from './fulfilment-order-white.js'; // @ts-expect-error

import GraphExplorer from './graph-explorer.js';
import GraphQL from './graphQL.js'; // @ts-expect-error

import Grid from './grid.js'; // @ts-expect-error

import GridRelations from './grid-relations.js'; // @ts-expect-error

import Hooks from './hooks.js'; // @ts-expect-error

import IconError from './icon-error.js'; // @ts-expect-error

import IconInfo from './icon-info.js'; // @ts-expect-error

import IconWarning from './icon-warning.js'; // @ts-expect-error

import Info from './info.js'; // @ts-expect-error

import InfoIllustration from './info-illustration.js'; // @ts-expect-error

import ItemRelations from './item-relations.js'; // @ts-expect-error

import Items from './items.js'; // @ts-expect-error

import Key from './key.js'; // @ts-expect-error

import ListView from './list-view.js'; // @ts-expect-error

import LocationPin from './location-pin.js'; // @ts-expect-error

import Magnifier from './magnifier.js';
import Media from './media.js'; // @ts-expect-error

import Merge from './merge.js'; // @ts-expect-error

import MissingImage from './missing-image.js'; // @ts-expect-error

import Multilingual from './multilingual.js'; // @ts-expect-error

import Order from './order.js'; // @ts-expect-error

import Phone from './phone.js'; // @ts-expect-error

import Pin from './pin.js'; // @ts-expect-error

import PipelineRemove from './pipeline-remove.js'; // @ts-expect-error

import PipelineStageChange from './pipeline-stage-change.js'; // @ts-expect-error

import Price from './price.js'; // @ts-expect-error

import Product from './product.js'; // @ts-expect-error

import Publish from './publish.js'; // @ts-expect-error

import Quantity from './quantity.js'; // @ts-expect-error

import Renew from './renew.js'; // @ts-expect-error

import RenewDue from './renew-due.js'; // @ts-expect-error

import Reorder from './reorder.js'; // @ts-expect-error

import Required from './required.js';
import Reset from './reset.js'; // @ts-expect-error

import Save from './save.js';
import Scale from './scale.js'; // @ts-expect-error

import ShapeDocument from './shape-document.js'; // @ts-expect-error

import Shapes from './shapes.js'; // @ts-expect-error

import Sort from './sort.js'; // @ts-expect-error

import Split from './split.js'; // @ts-expect-error

import Star from './star.js'; // @ts-expect-error

import Subscription from './subscription.js'; // @ts-expect-error

import SuccessIllustration from './success-illustration.js'; // @ts-expect-error

import SwitchIcon from './switch.js'; // @ts-expect-error

import Target from './target.js'; // @ts-expect-error

import TaxGroup from './tax-group.js'; // @ts-expect-error

import Tenant from './tenant.js';
import ThreeBoxes from './three-boxes.js';
import Topics from './topic.js'; // @ts-expect-error

import Translation from './translation.js'; // @ts-expect-error

import Trashcan from './trashcan.js'; // @ts-expect-error

import TrashcanBucket from './trashcan-bucket.js';
import TrashcanColor from './trashcan-color.js'; // @ts-expect-error

import TrashcanLid from './trashcan-lid.js';
import Triangle from './triangle.js'; // @ts-expect-error

import Unpublish from './unpublish.js'; // @ts-expect-error

import Update from './update.js'; // @ts-expect-error

import Usage from './usage.js'; // @ts-expect-error

import User from './user.js';
import UserAccount from './user-account.js';
import Visualize from './visualize.js'; // @ts-expect-error

import WarningIllustration from './warning-illustration.js'; // @ts-expect-error

import Billing from './billing-payments.js'; // @ts-expect-error

import More from './more.js';
export const Icon = {
  Add,
  Arrow,
  ArrowLeft,
  ArrowRight,
  BackArrow,
  BackArrow1,
  Bandwidth,
  Billing,
  BillingAddress,
  Calendar,
  CalendarColor,
  CalendarOutlined,
  Cancel,
  Catalogue,
  Check,
  Chevron,
  Chunk,
  Chunks,
  ChunkSlice,
  Clock,
  Cloud,
  CloudWithCross,
  CloudWithKey,
  CloudWithKeyHole,
  Code,
  Cog,
  Copy,
  Create,
  CurrencyBill,
  Customers,
  Dashboard,
  DeadTeddy,
  Delete,
  Document,
  Dots,
  DragCircles,
  Duplicate,
  Edit,
  Email,
  Folder,
  FormatBold,
  FormatBullet,
  FormatCode,
  FormatItalic,
  FormatLink,
  FormatList,
  FormatQuote,
  FormatUnderline,
  Fulfilment,
  FulfilmentOrder,
  FulfilmentOrderOpen,
  FulfilmentOrderWhite,
  GraphExplorer,
  GraphQL,
  Grid,
  GridRelations,
  Hooks,
  IconError,
  IconInfo,
  IconWarning,
  Info,
  InfoIllustration,
  ItemRelations,
  Items,
  Key,
  ListView,
  LocationPin,
  Magnifier,
  Media,
  Merge,
  MissingImage,
  Multilingual,
  More,
  Order,
  Phone,
  Pin,
  PipelineRemove,
  PipelineStageChange,
  Price,
  Product,
  Publish,
  Quantity,
  Renew,
  RenewDue,
  Reorder,
  Required,
  Reset,
  Save,
  Scale,
  ShapeDocument,
  Shapes,
  Sort,
  Split,
  Star,
  Subscription,
  SuccessIllustration,
  SwitchIcon,
  Target,
  TaxGroup,
  Tenant,
  ThreeBoxes,
  Topics,
  Translation,
  Trashcan,
  TrashcanBucket,
  TrashcanColor,
  TrashcanLid,
  Triangle,
  Unpublish,
  Update,
  Usage,
  User,
  UserAccount,
  Visualize,
  WarningIllustration
};